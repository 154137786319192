import Cookies from "js-cookie";
import { IBasketCookie } from "./IBasketCookie";

export class Basket {
	private readonly COOKIE_NAME = "ShoppingCart" as const;

	// tested
	public Init() {
		const label = this.getBasketLabel();
		document.querySelectorAll(".js__basket-count").forEach((x) => {
			x.innerHTML = label;
		});
	}

	// tested
	private getBasketLabel() {
		const serialisedCookieValue: string = this.retrieveSerialisedCookie();
		const basket = this.deserialiseBasket(serialisedCookieValue);
		const basketItems = this.getBasketQuantityArray(basket);
		return `${this.getBasketCountTotal(basketItems)}`;
	}

	// tested
	private getBasketQuantityArray(basket: IBasketCookie) {
		const basketItems = [] as number[];
		basket.Items.map((item) => basketItems.push(item.Quantity));
		return basketItems;
	}

	// tested
	private getBasketCountTotal(arr: number[]) {
		const basketCountTotal = arr.reduce((a: number, b: number) => a + b, 0);
		return basketCountTotal;
	}

	// tested
	private retrieveSerialisedCookie(): string {
		const cookieValue: string | undefined = Cookies.get(this.COOKIE_NAME);
		return typeof cookieValue === "string" ? cookieValue : "";
	}

	// tested
	private deserialiseBasket(serialisedBasket: string) {
		try {
			const decodedSerialisedBasket = decodeURIComponent(serialisedBasket);
			const cookieObject = JSON.parse(decodedSerialisedBasket) as IBasketCookie;
			if (typeof cookieObject !== "object" || Array.isArray(cookieObject.Items) === false) {
				return { Items: new Array<unknown>() } as IBasketCookie;
			}
			return cookieObject;
		} catch {
			return { Items: new Array<unknown>() } as IBasketCookie;
		}
	}
}
