import { gtag } from "./noop";
import { Basket } from "./Basket";
import { Search } from "./search";
import { NavigationDropdown } from "./NavigationDropdown";

if (typeof window["gtag"] !== "function") {
	window["gtag"] = gtag;
}

new Search().Init();
new Basket().Init();
new NavigationDropdown().Init();
