import { IInitialisable } from "../../interfaces/IInitialisable";
import { SearchWindowType } from "../../typings/WindowType";
import { ISearchRequest } from "./ISearchRequest";
import { ISearchResult } from "./ISearchResult";
import { querySelectorOrThrow } from "../../utils/dom/querySelectorOrThrow";
import { isEmptyOrWhitespace } from "../../utils/dom/isEmptyOrWhitespace";
import { SearchRequestEntity } from "./SearchRequestEntity";
import { SearchEvents } from "./searchevents";
import { checkURLPath } from "../../utils/checkURLPath";
import { environment } from "../../environments/environment";
import debounce from "lodash/debounce";

export class Search implements IInitialisable {
	private static debouncedTimeMs = 500;
	protected windowHelper = window as SearchWindowType;
	protected documentHelper = document;
	protected locationHelper = location;
	protected requestVerificationToken: string;

	private get gtag() {
		return this.windowHelper.gtag ?? (() => undefined);
	}

	public eventDispatcher = document.createElement("div");

	private querySelectorOrThrow = querySelectorOrThrow;
	private isEmptyOrWhitespaceHelper = isEmptyOrWhitespace;
	private encodeURIComponentHelper = encodeURIComponent;
	private checkURLPath = checkURLPath;

	private input: HTMLInputElement;
	private searchButton: HTMLElement;
	private isSearching: boolean = false;

	constructor() {
		this.handleKeyUp = debounce(this.handleKeyUp, Search.debouncedTimeMs, { trailing: true });
	}

	// tested
	Init(): void {
		const components = document.querySelectorAll<HTMLElement>(".global-header__top-nav__search");

		if (components.length > 0) {
			this.setUpComponent();
		}
	}

	// Tested
	private setUpComponent(): void {
		if (this.windowHelper.grecaptcha !== undefined) {
			this.windowHelper.grecaptcha.ready(() => this.handleRecaptchaReady());
		}
	}

	// tested
	private handleRecaptchaReady(): void {
		this.addEvents();
		this.requestVerificationToken = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, 'input[name="__RequestVerificationToken"]').value;
	}

	// tested
	private addEvents(): void {
		this.addClickEventListenerToSearchIcon(".global-header__search button");

		this.input = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__input");
		this.input.addEventListener("keyup", (evt) => this.handleKeyUp(evt));

		this.searchButton = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__button");
		this.searchButton.addEventListener("click", () => this.handleSearchClicked());

		this.windowHelper.addEventListener("resize", () => this.handleWindowResized());
	}

	// Tested
	private handleWindowResized() {
		const windowWidth = this.windowHelper.innerWidth;
		if (windowWidth >= 768) {
			this.updateClasses();
		}
	}

	// Tested
	private updateClasses() {
		const nav = this.documentHelper.querySelector(".global-header__top-nav__search") as HTMLElement;
		const navWrapper = this.documentHelper.querySelector(".global-header__top-nav") as HTMLElement;
		nav.classList.remove("searchClicked");
		navWrapper.classList.remove("searchClicked");
	}

	// tested
	private addClickEventListenerToSearchIcon(className: string): void {
		const result = this.documentHelper.querySelector<HTMLElement>(className);
		if (result) {
			result.addEventListener("click", () => {
				this.handleSearchToggle();
			});
		}
	}

	// tested
	private handleKeyUp(evt: KeyboardEvent): void {
		this.updateShouldAllowResetClass();
		if (evt.key === "Enter" || this.input.value.length >= 3) {
			this.isSearching = true;
			this.searchButton.setAttribute("disabled", "");
			this.handleButtonClicked();
		}
	}

	// Tested
	private handleSearchClicked() {
		if (!this.isEmptyOrWhitespaceHelper(this.input.value)) {
			this.handleResetButtonClicked();
		}
	}

	// Tested
	private async handleResetButtonClicked() {
		if (!this.isSearching) {
			const resultsWrapper = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__results");
			const resultsHTMLHolder = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".search-results__wrapper");
			const searchInput = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__input");

			this.input.value = "";
			await this.handleButtonClicked();
			this.updateShouldAllowResetClass();

			this.toggleElementClassName(resultsHTMLHolder, "showResults");
			this.toggleElementClassName(resultsWrapper, "showResults");
			this.toggleElementClassName(searchInput, "showResults");
		}
	}

	// Tested
	private updateShouldAllowResetClass() {
		if (this.isEmptyOrWhitespaceHelper(this.input.value)) {
			this.searchButton.classList.remove("should-allow-reset");
		} else {
			this.searchButton.classList.add("should-allow-reset");
		}
	}

	// Tested
	private async handleButtonClicked() {
		const inputValue = this.input.value;
		if (this.isEmptyOrWhitespaceHelper(inputValue)) {
			this.eventDispatcher.dispatchEvent(new CustomEvent(SearchEvents.EmptySearch));
		} else {
			await this.validateRecaptchaAndSearch(this.input.value);
		}
	}

	// Tested
	private handleSearchToggle(): void {
		const nav = this.documentHelper.querySelector(".global-header__top-nav__search") as HTMLElement;
		const navWrapper = this.documentHelper.querySelector(".global-header__top-nav") as HTMLElement;
		if (nav === null) throw new Error("Search could not be found");
		this.toggleElementClassName(nav, "searchClicked");
		this.toggleElementClassName(navWrapper, "searchClicked");
	}

	// Tested
	private toggleElementClassName(element: HTMLElement, className: string): void {
		element.classList.toggle(className);
	}

	// Tested
	private async sendFetchRequest(searchRequest: ISearchRequest): Promise<void> {
		try {
			const response = await this.windowHelper.fetch(searchRequest.ActionUrl, {
				method: "post",
				mode: "cors",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
					"Access-Control-Allow-Origin": "*",
				},
				body: this.encodeFormData(searchRequest),
			});

			if (!response.ok) {
				this.handleSearchFailed(response.statusText);
				throw new Error(`Error! status: ${response.status}`);
			}

			const responseBody: ISearchResult[] | { DisplayMessage: string } = await response.json();
			if (Array.isArray(responseBody)) {
				this.handleSearchComplete(searchRequest.SearchQuery, responseBody);
			} else {
				this.handleSearchFailed(responseBody.DisplayMessage);
			}
		} catch (error: unknown) {
			const errorMessage = error instanceof Error ? error.message : String(error);
			this.handleSearchFailed(errorMessage);
		}
	}

	// Tested
	private encodeFormData(searchRequest: ISearchRequest): string {
		const normalisedSearchQuery = searchRequest.SearchQuery.toLowerCase().replace(/\s+/gi, " ").trim();
		const urlEncodedDataPairs: string[] = [
			`${this.encodeURIComponentHelper("SearchQuery")}=${this.encodeURIComponentHelper(normalisedSearchQuery)}`,
			`${this.encodeURIComponentHelper("g-recaptcha-response")}=${this.encodeURIComponentHelper(searchRequest.RecaptchaToken)}`,
			`${this.encodeURIComponentHelper("__RequestVerificationToken")}=${this.encodeURIComponentHelper(searchRequest.RequestVerificationToken)}`,
		];
		return urlEncodedDataPairs.join("&").replace(/%20/g, "+");
	}

	// Tested
	private async validateRecaptchaAndSearch(searchQuery: string) {
		const recaptchaToken = await this.windowHelper.grecaptcha.execute(this.windowHelper.HstWeb.ReCaptchaSiteKey, { action: "submit" });
		this.eventDispatcher.dispatchEvent(new CustomEvent(SearchEvents.NonEmptySearch, { detail: new SearchRequestEntity(searchQuery, recaptchaToken, this.requestVerificationToken) }));
		const pagePath = this.locationHelper.href;
		const isWebTest = this.checkURLPath(pagePath, "web-test");
		const isLocal = this.checkURLPath(pagePath, "localhost");
		let searchPath = "";
		if (!isLocal) {
			searchPath = isWebTest ? `https://${this.locationHelper.hostname}/web-test${environment.SEARCH_URL}` : `https://${this.locationHelper.hostname}${environment.SEARCH_URL}`;
		} else {
			searchPath = isWebTest ? `/web-test${environment.SEARCH_URL}` : environment.SEARCH_URL;
		}

		const searchRequest: ISearchRequest = {
			SearchQuery: searchQuery,
			ActionUrl: searchPath,
			RecaptchaToken: recaptchaToken,
			RequestVerificationToken: this.requestVerificationToken,
		};

		this.sendFetchRequest(searchRequest);
	}

	// tested
	private handleSearchFailed(displayMessage: string): void {
		this.eventDispatcher.dispatchEvent(new CustomEvent(SearchEvents.Failed, { detail: displayMessage }));

		this.isSearching = false;
		this.searchButton.removeAttribute("disabled");
		const resultsWrapper = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__results");
		const resultsHTMLHolder = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".search-results__wrapper");
		const searchInput = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__input");
		const resultsButton = `<a href="${environment.SITE_ROOT}/courses" class="search-results__see_all__button" data-selenium-id="see-all-results-button">View all courses</a>`;
		const resultsHTML = this.generateResultsErrorItem();
		resultsHTMLHolder.innerHTML = resultsHTML + resultsButton;
		if (!resultsWrapper.classList.contains("showResults")) {
			this.toggleElementClassName(resultsHTMLHolder, "showResults");
			this.toggleElementClassName(resultsWrapper, "showResults");
			this.toggleElementClassName(searchInput, "showResults");
		}
	}

	// tested
	private handleSearchComplete(searchQuery: string, results: ISearchResult[]): void {
		if (results.length > 0) {
			this.reportSearchToAnalytics(searchQuery);
			this.displayResults(results, searchQuery);
		} else {
			this.reportNoSearchResultsToAnalytics(searchQuery);
			this.handleNoResults(searchQuery);
		}
	}

	// Tested
	private displayResults(results: ISearchResult[], query: string): void {
		this.isSearching = false;
		this.searchButton.removeAttribute("disabled");
		const resultsWrapper = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__results");
		const resultsHTMLHolder = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".search-results__wrapper");
		const searchInput = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__input");
		const resultsButton = this.generateResultsButton(results.length, query);
		const resultsToDisplay: ISearchResult[] = results.slice(0, 5);
		const resultsHTML = resultsToDisplay.reduce((pre, cur) => {
			return (pre += this.generateResultItem(cur));
		}, "");
		resultsHTMLHolder.innerHTML = resultsHTML + resultsButton;
		if (!resultsWrapper.classList.contains("showResults")) {
			this.toggleElementClassName(resultsHTMLHolder, "showResults");
			this.toggleElementClassName(resultsWrapper, "showResults");
			this.toggleElementClassName(searchInput, "showResults");
		}
	}

	// Tested
	private handleNoResults(query: string) {
		this.isSearching = false;
		this.searchButton.removeAttribute("disabled");
		const resultsWrapper = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__results");
		const resultsHTMLHolder = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".search-results__wrapper");
		const searchInput = this.querySelectorOrThrow<HTMLInputElement>(this.documentHelper, ".global-header__top-nav__search__input");
		const resultsButton = `<a href="${environment.SITE_ROOT}/courses" class="search-results__see_all__button" data-selenium-id="see-all-results-button">View all courses</a>`;
		const resultsHTML = this.generateNoResultsItem(query);
		resultsHTMLHolder.innerHTML = resultsHTML + resultsButton;
		if (!resultsWrapper.classList.contains("showResults")) {
			this.toggleElementClassName(resultsHTMLHolder, "showResults");
			this.toggleElementClassName(resultsWrapper, "showResults");
			this.toggleElementClassName(searchInput, "showResults");
		}
	}

	// Tested
	private generateResultItem(item: ISearchResult) {
		return `<div data-cmpt="product-card" class="cmpt" data-selenium-id="product-card_component"> 
		<a href="${item.InfoUrl}" class="product-card " tabindex="0">
		<div class="product-card__content">
			<div class="product-card__content__inner">
				<div class="product-card__title">
					<h2>${item.Title}</h2>
				</div>
			</div>
			<div class="product-card__bottom">
				<div class="product-card__price"><span>£${item.Price} <span class="vat">+VAT</span></span></div>
				<div class="product-card__btns">
					<div class="product-card__button">View course</div>
				</div>
			</div>
		</div>
	</a> </div>`;
	}

	// Tested
	private generateResultsButton(total: number, query: string) {
		return `<a href="${environment.SITE_ROOT}/courses#SearchQuery=${query}" class="search-results__see_all__button" data-selenium-id="see-all-results-button">
					See All Results (${total})
				</a>`;
	}

	// Tested
	private generateNoResultsItem(query: string) {
		return `<div class="search-results__no-results-text">
					<p data-selenium-id="search-results-message">Sorry, we couldn't find any courses for <br/><strong>'${query}'</strong>.</p>
					<p>Please try a different search</p>
				</div>`;
	}

	// Tested
	private generateResultsErrorItem() {
		return `<div class="search-results__no-results-text">
					<p data-selenium-id="search-results-message">Sorry, Something went wrong.</p>
					<p>Please try again</p>
				</div>`;
	}

	// tested
	private reportSearchToAnalytics(searchQuery: string): void {
		const pagePath = `${this.windowHelper.HstWeb.AppRoot}courses/?q=${this.encodeURIComponentHelper(searchQuery)}`;
		this.gtag("config", this.windowHelper.HstWeb.SearchTrackingKey, { page_path: pagePath });

		this.windowHelper.dataLayer = this.windowHelper.dataLayer || [];
		this.windowHelper.dataLayer.push({
			search_terms: searchQuery,
			event: "search_results",
		});
	}

	// tested
	private reportNoSearchResultsToAnalytics(searchQuery: string): void {
		this.gtag("event", "no results", { event_category: "search", event_label: searchQuery });

		this.windowHelper.dataLayer = this.windowHelper.dataLayer || [];
		this.windowHelper.dataLayer.push({
			search_terms: searchQuery,
			event: "no_results",
		});
	}
}
