export class NavigationDropdown {
	private documentHelper = document;
	private ourCoursesCheckbox: HTMLInputElement | null = null;
	private resourcesCheckbox: HTMLInputElement | null = null;

	// tested
	public Init() {
		this.ourCoursesCheckbox = this.documentHelper.querySelector<HTMLInputElement>("#global-header__main-menu__our-courses__dropdown");
		this.resourcesCheckbox = this.documentHelper.querySelector<HTMLInputElement>("#global-header__main-menu__resources__dropdown");
		if (this.ourCoursesCheckbox && this.resourcesCheckbox) {
			this.setupComponent();
		}
	}

	// tested
	private setupComponent() {
		this.addClickListeners(".js_nav_our-courses");
		this.addClickListeners(".js_nav_resources");
	}

	// tested
	private addClickListeners(selector: string) {
		const elements = Array.from(this.documentHelper.querySelectorAll<HTMLElement>(selector));
		elements.forEach((element) => {
			element.addEventListener("click", () => this.handleClick(element));
		});
	}

	// tested
	private handleClick(element: HTMLElement) {
		if (element.classList.contains("js_nav_our-courses")) {
			this.resourcesCheckbox!.checked = this.resourcesCheckbox!.checked === true && false;
		} else if (element.classList.contains("js_nav_resources")) {
			this.ourCoursesCheckbox!.checked = this.ourCoursesCheckbox!.checked === true && false;
		}
	}
}
